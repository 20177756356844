import { Card, CardContent } from "@/components/ui/card";
import { getYear } from "date-fns";

type Props = {
  currentEnergyRating: string;
  potentialEnergyRating: string;
  inspectionDate: string;
};

const EstimateFromEPC = ({
  currentEnergyRating,
  potentialEnergyRating,
  inspectionDate,
}: Props) => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <Card x-chunk="dashboard-01-chunk-1" className="w-full mb-4">
      <CardContent className="grid grid-cols-3 divide-x p-4">
        <div className="flex justify-center flex-col pr-3">
          <p className="text-2xl font-semibold">{currentEnergyRating}</p>
          <p className="text-xs font-normal text-muted-foreground">
            EPC Rating
          </p>
        </div>
        <div className="flex justify-center flex-col px-3">
          <p className="text-2xl font-semibold">{potentialEnergyRating}</p>
          <p className="text-xs font-normal text-muted-foreground">
            Potential Rating
          </p>
        </div>
        <div className="flex justify-center flex-col pl-3">
          <p className="text-2xl font-semibold">
            {year - getYear(inspectionDate)} years
          </p>
          <p className="text-xs font-normal text-muted-foreground">
            EPC Report Age
          </p>
        </div>
      </CardContent>
    </Card>
  );
};

export default EstimateFromEPC;
