import emojiEventsIcon from "@/assets/emojiEvents.svg";
import energySavingsLeafIcon from "@/assets/energySavingsLeaf.svg";
import monitoringIcon from "@/assets/monitoring.svg";
import wifiHomeIcon from "@/assets/wifiHome.svg";

export const config = [
  {
    id: 1,
    text: "Uncover strategies for energy and cost reduction.",
    icon: monitoringIcon,
  },
  {
    id: 2,
    text: "Decrease your carbon footprint and enhance your home’s environment.",
    icon: wifiHomeIcon,
  },
  {
    id: 3,
    text: "Rationalize costs on energy and home maintenance.",
    icon: energySavingsLeafIcon,
  },
  {
    id: 4,
    text: "Earn rewards from your bank for excellent home upkeep.",
    icon: emojiEventsIcon,
  },
];
