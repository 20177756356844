import type { Params } from "@/types";
import { useQuery } from "@tanstack/react-query";
import { getDashboard } from ".";

export const useGetDashboard = ({ userId, api_key }: Params) => {
  return useQuery({
    enabled: !!userId && !!api_key,
    queryKey: ["getDashboard"],
    queryFn: () => getDashboard({ userId, api_key }),
  });
};
