import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/error/")({
  component: ErrorPage,
});

function ErrorPage() {
  return (
    <div className="flex justify-center items-center h-full">
      <span className="text-base font-semibold">
        It looks like your link is broken. Please check the link with your Bank
        admin
      </span>
    </div>
  );
}
