// import { useGetDashboard } from "@/api/dashboard/queries";
// import { useGetTransactionsSteps } from "@/api/plaid/queries";
// import { useGetCheckUser } from "@/api/users/queries";
import { useUserIdAndApiKey } from "@/hooks/useUserIdAndApiKey";
import { Outlet, createRootRoute } from "@tanstack/react-router";
// import { useEffect } from "react";

export const Route = createRootRoute({
  component: RootComponent,
});

function RootComponent() {
  useUserIdAndApiKey();
  // const {
  //   data: checkUser,
  //   isPending: isPendingCheckUser,
  //   isError: isErrorCheckUser,
  // } = useGetCheckUser({
  //   userId,
  //   api_key,
  // });
  // const {
  //   data: score,
  //   isPending: isPendingScore,
  //   isError: isErrorScore,
  // } = useGetDashboard({
  //   userId,
  //   api_key,
  // });
  // const { data: transactionsSteps, isPending: isPendingTransactionsSteps } =
  //   useGetTransactionsSteps();

  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (isErrorScore || isErrorCheckUser) {
  //     navigate({ to: "/error" });
  //   }
  // }, [isErrorScore, isErrorCheckUser]);

  // useEffect(() => {
  //   if (!checkUser?.isApiPlusVisited) {
  //     navigate({ to: "/onboarding/welcome" });
  //   }

  //   if (checkUser?.isApiPlusVisited) {
  //     navigate({ to: "/dashboard" });
  //   }
  // }, [checkUser?.isApiPlusVisited]);

  // if (score.) {
  // TODO go to page bills
  // }

  // if (isPendingCheckUser || isPendingScore) {
  //   return <div>Loading ...</div>;
  // }

  return (
    <div className="max-w-[500px] m-auto">
      <Outlet />
    </div>
  );
}
