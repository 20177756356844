import type { Params, ParamsTransactionsCategory } from "@/types";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getPlaidLinkToken,
  getSyncTransactions,
  getTransactionsCategory,
  getTransactionsSteps,
  postSelectedTransactions,
  publicTokenExchange,
} from ".";
import type { LinkToken, PublicTokenExchangeParams } from "./types";

export const useGetPlaidLinkToken = (callback: (data: LinkToken) => void) => {
  return useMutation({
    mutationKey: ["getPlaidLinkToken"],
    mutationFn: ({ userId, api_key }: Params) =>
      getPlaidLinkToken({ userId, api_key }),
    onSuccess: (data) => {
      callback(data);
    },
  });
};

export const usePublicTokenExchange = (callback: () => void) => {
  return useMutation({
    mutationKey: ["publicTokenExchange"],
    mutationFn: ({
      userId,
      api_key,
      public_token,
    }: PublicTokenExchangeParams) =>
      publicTokenExchange({
        userId,
        api_key,
        public_token,
      }),
    onSuccess: () => {
      callback();
    },
  });
};

export const useGetTransactionsSteps = () => {
  return useQuery({
    queryKey: ["getTransactionsSteps"],
    queryFn: () => getTransactionsSteps(),
  });
};

export const useGetTransactionsCategory = ({
  category,
  userId,
}: ParamsTransactionsCategory) => {
  return useQuery({
    queryKey: ["getTransactionsCategory"],
    queryFn: () => getTransactionsCategory({ category, userId }),
  });
};

export const usePostSelectedTransactions = (type: string) => {
  return useQuery({
    queryKey: ["postSelectedTransactions"],
    queryFn: () => postSelectedTransactions(type),
  });
};

export const useGetSyncTransactions = ({ userId, api_key }: Params) => {
  return useQuery({
    queryKey: ["getSyncTransactions"],
    queryFn: () => getSyncTransactions({ userId, api_key }),
  });
};
