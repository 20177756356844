import backIcon from "@/assets/back.svg";
import secondStepIcon from "@/assets/secondStep.svg";
import { Button } from "@/components/ui/button";
import { config } from "@/configs/app-information";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

export const Route = createFileRoute("/onboarding/application-information")({
  component: AppInformation,
});

function AppInformation() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate({ to: "/dashboard" });
  };

  const goBack = () => {
    navigate({ to: "/onboarding/welcome" });
  };

  return (
    <div className="flex min-h-screen p-6 flex-col justify-between items-center">
      <div>
        <div className="flex justify-between items-center min-w-full mb-5">
          <Button onClick={goBack} variant="ghost" size="icon">
            <img className="size-6" src={backIcon} alt="Go back icon" />
          </Button>
          <img src={secondStepIcon} alt="Step icon" />
          <div />
        </div>
        <h1 className="font-semibold text-[32px] mb-4">
          Gain all advantages from App
        </h1>
        <p className="text-sm font-semibold mb-6">
          Our application streamlines your lifestyle and finances. It collects
          data on your energy consumption, home improvements, and expenses via
          Plaid. This enables you to:
        </p>
        <div className="py-0.5 px-4 rounded-lg border divide-y mb-5">
          {config.map((item) => (
            <div className="py-3 flex items-center" key={item.id}>
              <div className="bg-backgroundWelcome p-2.5 rounded-full mr-6">
                <img src={item.icon} alt="icon" />
              </div>
              <p className="font-medium text-sm">{item.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full">
        <Button
          onClick={handleClick}
          variant="secondary"
          className="text-base font-semibold bg-button hover:bg-button text-white flex w-full mb-6"
        >
          Next
        </Button>
        <p className="text-xs font-semibold text-center mb-2">
          Powered by Smartlayer
        </p>
      </div>
    </div>
  );
}
