import mapPin from "@/assets/mapPin.svg";

type Props = {
  address: string;
};

const Header = ({ address }: Props) => {
  return (
    <div className="p-4 mb-2">
      <p className="text-sm font-semibold text-center">Your home address</p>
      <p className="text-xs font-medium text-center flex justify-center">
        <img src={mapPin} alt="Icon" />
        {address}
      </p>
    </div>
  );
};

export default Header;
