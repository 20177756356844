import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export const useUserStore = create<State & Actions>()(
  persist(
    (set) => ({
      userId: "",
      isConnectedBank: false,
      api_key: "",
      updateState: ({ userId, api_key, isConnectedBank }) =>
        set(() => ({ userId, api_key, isConnectedBank })),
    }),
    {
      name: "user-storage",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
