import firstStepIcon from "@/assets/firstStep.svg";
import homeWelcomeIcon from "@/assets/home.svg";
import { Button } from "@/components/ui/button";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

export const Route = createFileRoute("/onboarding/welcome")({
  component: Welcome,
  notFoundComponent: () => {
    return <p>Not Found (on root route)</p>;
  },
});

function Welcome() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate({ to: "/onboarding/application-information" });
  };

  return (
    <div className="flex min-h-screen p-6 flex-col justify-between items-center">
      <img src={firstStepIcon} alt="Step icon" />
      <div className="flex justify-center mb-2 rounded-full bg-gradient-radial from-gradient-start/30 to-gradient-stop">
        <img src={homeWelcomeIcon} alt="Icon" />
      </div>
      <div>
        <div className="flex justify-between mb-6 items-end text-xl font-semibold">
          <h1>What is HomeHealth?</h1>
        </div>
        <p className="text-sm font-semibold mb-2">
          Understand your homes performance
        </p>
        <p className="text-sm font-normal mb-12">
          HomeHealth looks to really understand how your home performs in it
          energy usage and its overall efficiency. It allows you to track all
          your home owning bills in one central place
        </p>
        <Button
          onClick={handleClick}
          variant="secondary"
          className="text-base font-semibold bg-button hover:bg-button text-white flex w-full mb-6"
        >
          Next
        </Button>
        <p className="text-xs font-semibold text-center mb-2">
          Powered by Smartlayer
        </p>
      </div>
    </div>
  );
}
