import type { Params, ParamsTransactionsCategory } from "@/types";
import { http } from "..";
import type {
  LinkToken,
  PublicTokenExchange,
  PublicTokenExchangeParams,
  Steps,
  TransactionsCategory,
} from "./types";

export const getPlaidLinkToken = async (params: Params): Promise<LinkToken> => {
  return http.url("plaid/link-token").query(params).get().json();
};

export const publicTokenExchange = async (
  params: PublicTokenExchangeParams,
): Promise<PublicTokenExchange> => {
  return http.url("plaid/token-exchange").query(params).get().json();
};

// 1
export const getTransactionsSteps = async (): Promise<Steps> => {
  return http.url("plaid/transactions/steps").get().json();
};

// 1
export const getSyncTransactions = async (params: Params) => {
  return http.url("plaid/sync-transactions").query(params).get().json();
};

// 2
export const getTransactionsCategory = async (
  params: ParamsTransactionsCategory,
): Promise<TransactionsCategory> => {
  return http.url("plaid/transactions/").query(params).get().json();
};

// 2
export const postSelectedTransactions = async (type: string) => {
  return http.url(`save-selected-transactions/${type}`).post().json();
};
