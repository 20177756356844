import { useUserStore } from "@/store/user";
import { useSearch } from "@tanstack/react-router";
import { useEffect } from "react";

export function useUserIdAndApiKey() {
  const { userId, api_key } = useSearch({
    strict: false,
  }) as { userId?: string; api_key?: string };
  const {
    userId: userIdStore,
    api_key: apiKeyStore,
    updateState,
  } = useUserStore((state) => state);

  useEffect(() => {
    if (userId && api_key) {
      updateState({ userId, api_key, isConnectedBank: false });
    }
  }, [userId, api_key, updateState]);

  return {
    userId: userId ? userId : userIdStore,
    api_key: api_key ? api_key : apiKeyStore,
  };
}
