import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";

type BillTotalProps = {
  bill: number;
};
type BillProps = {
  data: {
    id: string;
    bill: number;
    title: string;
    subTitle: string;
    icon: JSX.Element;
  };
};

export const BillTotal = ({ bill }: BillTotalProps) => {
  return (
    <div className="py-3 px-4 flex justify-between f-1 w-full">
      <span className="font-normal text-sm">Bill total </span>
      <span className="font-semibold text-sm">£ {bill}</span>
    </div>
  );
};

export const Bill = ({ data }: BillProps) => {
  return (
    <Label
      htmlFor={data.id}
      className="py-3 px-4 flex justify-between f-1 w-full"
    >
      <div className="flex justify-center items-center">
        {data.icon}
        <div className="ml-2">
          <div className="font-medium text-medium">{data.title}</div>
          <div className="font-normal text-sm">{data.subTitle}</div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <span className="font-semibold text-sm mr-2">£ {data.bill}</span>
        <Checkbox
          className="data-[state=checked]:bg-checkboxActive data-[state=checked]:border-0 border-2 border-slate-200 h-5 w-5"
          id={data.id}
        />
      </div>
    </Label>
  );
};
