import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const EnergyUsage = () => {
  const isFirst = true;
  return (
    <div className="flex flex-col w-full">
      <p className="text-base font-semibold mb-4">Energy usage</p>
      {isFirst ? (
        <div className="rounded-md p-4 text-sm font-medium bg-backgroundSecondary">
          We dont have that information yet. Tell us more about your energy
          consumption or connect your Plaid to get more accurate readings.
        </div>
      ) : (
        <Tabs defaultValue="daily" className="w-[400px]">
          <TabsList className="bg-background">
            <TabsTrigger
              value="daily"
              className="border-b rounded-none	border-slate-200 data-[state=active]:border-borderActive "
            >
              Daily
            </TabsTrigger>
            <TabsTrigger
              value="weekly"
              className="border-b rounded-none	border-slate-200 data-[state=active]:border-borderActive "
            >
              Weekly
            </TabsTrigger>
            <TabsTrigger
              value="monthly"
              className="border-b rounded-none	border-slate-200 data-[state=active]:border-borderActive "
            >
              Monthly
            </TabsTrigger>
          </TabsList>
          <TabsContent value="daily">daily bars</TabsContent>
          <TabsContent value="weekly">weekly bars</TabsContent>
          <TabsContent value="monthly">monthly bars</TabsContent>
        </Tabs>
      )}
    </div>
  );
};

export default EnergyUsage;
