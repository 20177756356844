// import { useGetPlaidLinkToken } from "@/api/plaid/queries";
import backIcon from "@/assets/back.svg";
import closeIcon from "@/assets/close.svg";
import firstStepIcon from "@/assets/firstStep.svg";
import secondStepIcon from "@/assets/secondStep.svg";
import thirdWelcomeIcon from "@/assets/thirdWelcome.svg";
import { Button } from "@/components/ui/button";
// import { useUserStore } from "@/store/user";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useState } from "react";

export const Route = createFileRoute("/onboarding/connect-bank-account")({
  component: ConnectBankAccount,
});

function ConnectBankAccount() {
  const navigate = useNavigate();
  const [screenNumber, setScreenNumber] = useState(0);
  // const { userId, api_key } = useUserStore((state) => state);
  // const { mutateAsync } = useGetPlaidLinkToken((data) => {
  //   navigate({ to: "/plaid/$token", params: { token: data?.link_token } });
  // });

  const getPlaidLinkToken = () => {
    navigate({ to: "/onboarding/connected-bank-account" });
    // mutateAsync({ userId, api_key });
  };

  const handleClick = () => {
    if (screenNumber === 0) {
      setScreenNumber((prev) => prev + 1);
    } else {
      getPlaidLinkToken();
    }
  };

  const goBack = () => {
    setScreenNumber(0);
  };

  return (
    <div className="bg-backgroundWelcome flex min-h-screen p-6 flex-col justify-between content-center">
      <div className="grid grid-cols-3 items-center">
        <div className="col-span-1 justify-self-start">
          {screenNumber > 0 && (
            <Button onClick={goBack} variant="ghost" size="icon">
              <img className="size-6" src={backIcon} alt="Go back icon" />
            </Button>
          )}
        </div>
        <div className="col-span-1 justify-self-center">
          <img
            src={screenNumber > 0 ? secondStepIcon : firstStepIcon}
            alt="Step icon"
          />
        </div>
        <div className="col-span-1 justify-self-end">
          <Button onClick={getPlaidLinkToken} variant="ghost" size="icon">
            <img className="size-6" src={closeIcon} alt="Close icon" />
          </Button>
        </div>
      </div>
      {screenNumber === 0 && (
        <div className="flex justify-center mb-2">
          <img src={thirdWelcomeIcon} alt="Icon" />
        </div>
      )}
      <div>
        <h1 className="text-xl font-semibold mb-6">
          Connect your bank account
        </h1>
        {screenNumber > 0 ? (
          <>
            <p className="text-[15] font-normal mb-6">
              Securely connect your other bank accounts to enable SmartLayer to
              access your transaction history for the purposes of providing you
              money saving insights and home energy efficiency increasing
              insights. Note: this does not enable SmartLayer to do anything
              other than view the transactions for the accounts
            </p>
            <p className="text-[15] font-normal mb-12 italic">
              SmartLayer is an agent of Plaid Financial Ltd., an authorised
              payment institution regulated by the Financial Conduct Authority
              under the Payment Services Regulations 2017 (Firm Reference
              Number: 804718). Plaid provides you with regulated account
              information services through SmartLayer as its agent.
            </p>
          </>
        ) : (
          <>
            <p className="text-sm font-semibold mb-2">
              Connect your account and see all your in and outgoings in one
              place
            </p>
            <p className="text-sm font-normal mb-12">
              Tired of juggling bills? Open banking brings your home finances
              together for a clearer view of your finances. Connect today and
              see your dashboard work for you.
            </p>
          </>
        )}
        <Button
          onClick={handleClick}
          variant="secondary"
          className="text-base font-semibold bg-button hover:bg-button text-white flex w-full mb-6"
        >
          {screenNumber > 0 ? "Continue" : "Next"}
        </Button>
        <p className="text-xs font-semibold text-center mb-2">
          Powered by Smartlayer
        </p>
      </div>
    </div>
  );
}
