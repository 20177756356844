import { useGetDashboard } from "@/api/dashboard/queries";
import { Button } from "@/components/ui/button";
import { useUserStore } from "@/store/user";
// import { useUserStore } from "@/store/user";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

export const Route = createFileRoute("/onboarding/new-score")({
  component: NewScore,
});

function NewScore() {
  const { userId, api_key, updateState } = useUserStore((state) => state);
  const { data } = useGetDashboard({ userId, api_key });
  const navigate = useNavigate();

  if (!data) return;

  const goToDashboard = () => {
    updateState({ userId, api_key, isConnectedBank: true });
    navigate({ to: "/dashboard" });
  };

  return (
    <div className="bg-backgroundWelcome flex min-h-screen p-6 flex-col justify-between content-center">
      <div>
        <h1 className="text-4xl font-semibold mb-3 text-center">Woohoo!</h1>
        <p className="font-medium text-base text-center text-button/70">
          You provided us your bill info and your score just jumped!
        </p>
      </div>
      <div className="flex flex-col items-center">
        <p className="font-medium text-xl">You new score is</p>
        <h1 className="text-9xl font-bold mb-10">{data.score + 50}</h1>
        <p className="font-medium text-base text-center mb-4">
          Great job!
          <br />
          Your home health score has increased by
        </p>
        <span className="text-lg font-semibold py-1 px-2 bg-button/10 rounded-lg">
          + 50 points
        </span>
      </div>
      <div>
        <Button
          onClick={goToDashboard}
          variant="secondary"
          className="text-base font-semibold bg-button hover:bg-button text-white flex w-full mb-6"
        >
          Go to Dashboard
        </Button>
        <p className="text-xs font-semibold text-center mb-2">
          Powered by Smartlayer
        </p>
      </div>
    </div>
  );
}
