import { usePublicTokenExchange } from "@/api/plaid/queries";
import { useUserStore } from "@/store/user";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { usePlaidLink } from "react-plaid-link";

export const Route = createFileRoute("/plaid/$token")({
  component: Plaid,
});

function Plaid() {
  const navigate = useNavigate();
  const { token } = Route.useParams();
  const { userId, api_key } = useUserStore((state) => state);
  const { mutateAsync } = usePublicTokenExchange(() => {
    navigate({ to: "/onboarding/connected-bank-account" });
  });

  const { open, ready } = usePlaidLink({
    token: token || "",
    onSuccess: (public_token: string) => {
      mutateAsync({
        userId,
        api_key,
        public_token,
      });
    },
    onExit: (err, metadata) => {
      console.log("onExit", err, metadata);
      navigate({ to: "/dashboard" });
    },
    onEvent: (eventName, metadata) => {
      console.log("onEvent", eventName, metadata);
    },
  });

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  return <div />;
}
