import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { useNavigate } from "@tanstack/react-router";

const ConnectToBank = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate({ to: "/onboarding/connect-bank-account" });
  };

  return (
    <Card x-chunk="dashboard-01-chunk-1" className="w-full mb-4">
      <CardHeader className="flex items-center">
        <p className="text-xs font-medium">
          See all your finances in one place, quick & easy
        </p>
      </CardHeader>
      <CardContent className="p-4 pt-0 flex justify-center">
        <Button className="w-full" onClick={handleClick}>
          Connect my bank account
        </Button>
      </CardContent>
    </Card>
  );
};
export default ConnectToBank;
