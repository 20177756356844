import closeIcon from "@/assets/close.svg";
import doneIcon from "@/assets/done.svg";
import { Button } from "@/components/ui/button";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

export const Route = createFileRoute("/onboarding/connected-bank-account")({
  component: ConnectedBankAccount,
});

function ConnectedBankAccount() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate({ to: "/plaid/loading-transactions" });
  };

  return (
    <div className="flex min-h-screen p-6 flex-col justify-between content-center">
      <Button onClick={handleClick} variant="ghost" size="icon">
        <img className="size-6" src={closeIcon} alt="Close icon" />
      </Button>
      <div className="flex flex-col justify-center ">
        <img
          src={doneIcon}
          alt="Icon"
          className="h-[210px] rounded-full bg-gradient-radial from-gradient-start/30 to-gradient-stop"
        />
        <p className="text-2xl text-center font-medium my-4">
          You’re connected
        </p>
        <p className="text-sm text-center font-medium mb-2">
          Great work, now all you need to do is select your salary and your
          bills that related to your home owning costs
        </p>
      </div>
      <div>
        <Button
          onClick={handleClick}
          variant="secondary"
          className="text-base font-semibold bg-button hover:bg-button text-white flex w-full mb-6"
        >
          Next
        </Button>
      </div>
    </div>
  );
}
