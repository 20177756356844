import { Card, CardContent } from "@/components/ui/card";

type Props = {
  energyConsumptionCurrent: string;
  co2: string;
};

const ConsumptionAndEmissions = ({ energyConsumptionCurrent, co2 }: Props) => {
  const consumption = (Number(energyConsumptionCurrent) / 12).toFixed(2);

  return (
    <Card x-chunk="dashboard-01-chunk-1" className="w-full mb-4">
      <CardContent className="grid grid-cols-2 divide-x p-4">
        <div className="flex justify-center flex-col pr-3">
          <p className="text-2xl font-semibold">{consumption} kWh/m2</p>
          <p className="text-xs font-normal text-muted-foreground">
            Monthly Energy Consumption
          </p>
        </div>
        <div className="flex justify-center flex-col pl-3">
          <p className="text-2xl font-semibold">{co2} kg CO2</p>
          <p className="text-xs font-normal text-muted-foreground">
            Monthly Gas Emission
          </p>
        </div>
      </CardContent>
    </Card>
  );
};

export default ConsumptionAndEmissions;
