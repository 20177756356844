import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import Pie from "./pie";

type Props = { score: number };

const HomeHealthScore = ({ score }: Props) => {
  const percentage = (score / 1000) * 100;

  return (
    <Card
      x-chunk="dashboard-01-chunk-1"
      className="bg-backgroundWelcome mb-4 w-full"
    >
      <CardHeader className="flex flex-row items-center justify-between space-y-0 p-4 pb-6">
        <span className="text-base font-semibold">HomeHealth Score</span>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Button
                variant="ghost"
                size="icon"
                className="rounded-xl border-black border w-5 h-5"
              >
                i
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>
                Home health score is a score that is calculated by the system
                for further work with home owners and encouraging them to
                improve the energy efficiency of their home
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </CardHeader>
      <CardContent className="p-4 pt-0">
        <div className="flex justify-between">
          <Pie percentage={percentage} color="black" />
          <div className="flex flex-col items-end">
            <span className="text-7xl font-bold">{score}</span>
            <span className="text-base font-semibold">/1000</span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
export default HomeHealthScore;
