import { Button } from "@/components/ui/button";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

export const Route = createFileRoute("/onboarding/")({
  component: Welcome,
  notFoundComponent: () => {
    return <p>Not Found (on root route)</p>;
  },
});

function Welcome() {
  const navigate = useNavigate();

  return (
    <div>
      <div>I'm a Index</div>
      <Button
        onClick={() => {
          navigate({ to: "/onboarding/welcome" });
        }}
        variant="secondary"
        className="g-sky-700 px-4 py-2 text-red hover:bg-sky-800 flex w-full"
      >
        Go to Address
      </Button>
    </div>
  );
}
