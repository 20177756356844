import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Separator } from "@/components/ui/separator";
import { ChevronDown } from "lucide-react";

const config = {
  homeOwnership: [
    {
      name: "Insurance",
      value: 90.0,
      color: "hsla(72, 56%, 80%, 1)",
    },
    {
      name: "Energy",
      value: 175.0,
      color: "hsla(73, 33%, 57%, 1)",
    },
    {
      name: "Council tax",
      value: 110.29,
      color: "hsla(73, 100%, 72%, 1)",
    },
    {
      name: "Mortgage",
      value: 900.0,
      color: "hsla(72, 53%, 27%, 1)",
    },
  ],
  total: 1275.29,
};

const HomeOwnership = () => {
  config.homeOwnership.sort((a, b) => b.value - a.value);

  return (
    <div className="w-full mb-4">
      <Accordion
        type="single"
        collapsible
        className="border-gray-400 border rounded-lg"
      >
        <AccordionItem
          value="item-1"
          className="border-0 bg-backgroundBills rounded-lg"
        >
          <AccordionTrigger className="border-b-gray-400	border-b rounded-lg p-4 flex-col [&>svg:last-of-type]:hidden bg-background">
            <div className="flex justify-between w-full items-center mb-3">
              <p className="mr-auto text-left font-normal	text-xs">
                Total monthly homeownership cost
              </p>
              <p className="font-semibold	text-2xl mr-2">£{config.total}</p>
              <ChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200" />
            </div>
            <div className="w-full flex">
              {config.homeOwnership.map((item, index) => (
                <div
                  key={item.name}
                  style={{
                    backgroundColor: item.color,
                    width: `calc(${(item.value / config.total) * 100}% + 3px)`,
                    zIndex: 100 - index,
                  }}
                  className="h-1.5 rounded-lg mr-[-5px]"
                />
              ))}
            </div>
          </AccordionTrigger>
          <AccordionContent className="p-4 border-0">
            {config.homeOwnership.map((item) => (
              <div className="flex justify-between mb-2" key={item.name}>
                <div className="flex items-center">
                  <div
                    style={{
                      backgroundColor: item.color,
                    }}
                    className="h-3 mr-1	w-3 rounded-md"
                  />
                  <div className="font-medium	text-xs">{item.name}</div>
                </div>
                <div className="font-semibold	text-sm">£{item.value}</div>
              </div>
            ))}
            <Separator className="mb-2 bg-divider" />
            <div className="flex justify-between mb-2">
              <div className="font-medium	text-xs">Homeowner total</div>
              <div className="font-semibold	text-sm">£{config.total}</div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default HomeOwnership;
