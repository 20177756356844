// import {
//   useGetSyncTransactions,
//   useGetTransactionsSteps,
// } from "@/api/plaid/queries";
import loadingIcon from "@/assets/loading.svg";
import questionIcon from "@/assets/question.svg";
// import { useUserStore } from "@/store/user";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

export const Route = createFileRoute("/plaid/loading-transactions")({
  component: LoadingTransactions,
});

function LoadingTransactions() {
  const navigate = useNavigate();
  // const { userId, api_key } = useUserStore((state) => state);
  // const { data: syncTransactions, isPending: isPendingSyncTransactions } =
  //   useGetSyncTransactions({
  //     userId,
  //     api_key,
  //   });
  // const { data: transactionsSteps, isPending: isPendingTransactionsSteps } =
  //   useGetTransactionsSteps();

  // console.log(syncTransactions, transactionsSteps);

  // if (!isPendingSyncTransactions && !isPendingTransactionsSteps) {
  //   console.log("go to Bills");
  // }

  setTimeout(() => navigate({ to: "/plaid/bills" }), 1000);

  return (
    <div className="flex min-h-screen p-6 flex-col justify-between content-center">
      <div />
      <div className="flex flex-col justify-center items-center rounded-full bg-gradient-radial from-gradient-start/30 to-gradient-stop">
        <img
          src={loadingIcon}
          alt="Icon"
          className="mb-12 h-[160px] w-[160px]"
        />
        <p className="text-2xl text-center font-medium my-4">
          Stay here, we carefully processing your transactions...
        </p>
        <p className="text-sm text-center font-medium mb-2 text-muted-foreground">
          This could take a while to collect your bills data
        </p>
      </div>
      <div className="border rounded-lg p-4 font-medium text-sm border-muted-foreground">
        <div className="mb-2 flex items-center">
          <img src={questionIcon} alt="Icon" className="mr-2.5" />
          <p>Did ypu know</p>
        </div>
        <p>
          Compared to energy saving bulbs, one traditional incandescent light
          bulb can cost an extra £34 a year
        </p>
      </div>
    </div>
  );
}
