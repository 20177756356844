// import { useGetTransactionsCategory } from "@/api/plaid/queries";
import backIcon from "@/assets/back.svg";
import questionIcon from "@/assets/question.svg";
import { Button } from "@/components/ui/button";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Building2, Home, Landmark, LaptopMinimal, Zap } from "lucide-react";
import { Bill, BillTotal } from "./-components/cardBill";

const config = [
  {
    id: "1",
    bill: 900,
    title: "Mortgage",
    subTitle: "1st March",
    icon: <Landmark />,
  },
  {
    id: "2",
    bill: 175,
    title: "Octopus Energy",
    subTitle: "7th March",
    icon: <Zap />,
  },
  {
    id: "3",
    bill: 110,
    title: "L/B of Lambeth",
    subTitle: "1st March",
    icon: <Building2 />,
  },
  {
    id: "4",
    bill: 90,
    title: "Home Insurance",
    subTitle: "12th March",
    icon: <Home />,
  },
  {
    id: "5",
    bill: 10,
    title: "Netflix",
    subTitle: "28th March",
    icon: <LaptopMinimal />,
  },
];

export const Route = createFileRoute("/plaid/bills")({
  component: Bills,
});

function Bills() {
  const navigate = useNavigate();

  const goBack = () => {
    console.log("go back");
  };

  const goToNewScore = () => {
    navigate({ to: "/onboarding/new-score" });
  };

  // const { data } = useGetTransactionsCategory({ category, userId });

  return (
    <div className="flex items-center flex-col p-6 bg-backgroundBills min-h-screen justify-between">
      <div className="w-full  mb-8">
        <div className="flex items-center justify-between w-full mb-6">
          <Button onClick={goBack} variant="ghost" size="icon">
            <img className="size-6" src={backIcon} alt="Close icon" />
          </Button>
          <Button onClick={goBack} variant="ghost" size="icon">
            <img className="size-6" src={questionIcon} alt="Close icon" />
          </Button>
        </div>
        <div className="mb-8">
          <h1 className="mb-[18px] font-semibold text-4xl">
            Can you confirm this for us?
          </h1>
          <p className="font-medium	text-base">
            Select all the payments that are your household bills
          </p>
        </div>
        <div className="bg-background w-full rounded-lg flex items-center flex-col divide-divide divide-y">
          <BillTotal bill={1275} />
          {config.map((item) => (
            <Bill data={item} key={item.id} />
          ))}
        </div>
      </div>
      <Button
        onClick={goToNewScore}
        variant="secondary"
        className="text-base font-semibold bg-button hover:bg-button text-white flex w-full mb-6"
      >
        Confirm and go next
      </Button>
    </div>
  );
}
